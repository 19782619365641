body {
    margin: 0;
    padding: 0;
    background-color: #f5f6f8;
    font-family: 'Roboto', sans-serif;
}

/*** Common elements ***/
ul {
    padding-left: 0;
}

li {
    list-style: none;
}

a {
    transition: ease-in-out 0.5s;
}

input:focus {
    outline: 0 none;
}

.form-control {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #818181;
    height: 54px;
    background: #f2f2f2;
    border-radius: 10px 10px;
}

input.form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #818181;
}

input.form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: #818181;
}

input.form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: #818181;
}

.btn-rnd-black {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    cursor: pointer;
    background: #1C1C1C;
    border: 0 none;
    border-radius: 10px 10px;
    transition: ease-in-out 0.5s;
}

.btn-rnd-black:hover {
    background: #9c9c9c;
    color: #fff;
}

.btn-rnd-red {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    cursor: pointer;
    background: #B81E1E;
    border: 0 none;
    border-radius: 10px 10px;
    transition: ease-in-out 0.5s;
}

.btn-rnd-red:hover {
    background: #661313;
}

/* custom-checkbox */
.custom-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 1px solid #999;
    border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked~.checkmark {
    background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #111;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/** user-account-page **/
.user-account-page {
    min-height: calc(100vh - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-account-page .form-container {
    width: 90%;
    max-width: 1300px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 20px 20px;
    box-shadow: 0 4px 8px #aaa;
    background: #52608E;
}

.user-account-page .form-container .logo-col {
    padding-top: 100px;
    padding-bottom: 100px;
    background: #fff;
    /* background: #5B73E8; */
}

.user-account-page .logo-col .logo-content-frame,
.user-account-page .form-col .content-frame {
    color: #2A2A2A;
}

.user-account-page .form-col .content-frame .text-danger {
    text-align: left;
    padding-top: 2px;
}

.user-account-page .logo-col .logo-frame {
    width: 100%;
}

.user-account-page .logo-col .logo-frame .logo {
    width: 233px;
    height: 71px;
    display: block;
    margin: 20px auto;
    background: url(../images/logo.png) no-repeat;
    background-size: 100%;
}

.user-account-page .logo-col .title {
    width: 100%;
    text-align: center;
    font-size: 42px;
    font-weight: 500;
}

.user-account-page .logo-col .lbl {
    display: block;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
}

.user-account-page .form-col {
    padding-top: 100px;
    padding-bottom: 100px;
}

.user-account-page .form-col .content-frame {
    max-width: 420px;
    margin: 0 auto;
    text-align: center;

}

.user-account-page .form-col .content-frame .title {
    font-size: 36px;
    font-weight: 500;
    padding-bottom: 20px;
    color: #fff;
}

.user-account-page .form-col .lbl-forgot-pswd {
    margin-bottom: 30px;
}

.user-account-page .form-col .lbl-forgot-pswd a {
    font-size: 16px;
    font-weight: 500;
    color: #e9e9e9;
}

.user-account-page .form-col .lbl-forgot-pswd a:hover {
    color: #000;
    text-decoration: none;
}

.user-account-page .form-col .btn-row {
    width: 100%;
}

.user-account-page .form-col .btn-row .btn-rnd-black {
    width: 100%;
    height: 54px;
    margin-top: 20px;
}


/*** Footer ***/
footer {
    /* background: #c9c9c9; */
    border-top: 1px solid #ccc;
}

footer .footer-content-frame {
    width: 90%;
    max-width: 1300px;
    margin: 10px auto;
    display: flex;
    justify-content: space-between;
}

footer .footer-content-frame .lbl-copy {
    font-size: 13px;
}

footer .footer-content-frame .lbl-copy a {
    font-weight: 600;
    color: #52608E;
}

footer .footer-content-frame nav.foot-list {
    font-size: 13px;
}

footer .footer-content-frame nav.foot-list a {
    color: #52608E;
    margin: 0 10px;
    position: relative;
}

footer .footer-content-frame nav.foot-list a::after {
    content: '';
    display: inline-block;
    width: 1px;
    height: 13px;
    position: absolute;
    right: -12px;
    top: 1px;
    background: #52608E;
}

footer .footer-content-frame nav.foot-list a:last-child:after {
    display: none;
}

footer .footer-content-frame nav.foot-list a:hover {
    color: #000;
}

.eventHeight {
    height: auto;
}

.fc .fc-button-primary {
    background-color: #5b73e8 !important;
    border-color: #5b73e8 !important;
}

.fc .fc-button-primary:focus {
    box-shadow: none !important;
}

.fc-button-group>.fc-button.fc-button-active {
    color: #fff;
    background-color: #495cba !important;
    border-color: #4456ae !important;
}

.h-172 {
    height: 172px;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #dfdfdf !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search-box .form-control {
    line-height: 23px;
    padding-top: 8px;
}

.btn-primary.custHeight {
    height: 27px;
    padding: 3px;
}

.accordWrap .lg-txt {
    max-width: 290px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ml-1 {
    margin-left: 4px !important;
}

.text-right {
    text-align: right;
}

.col-md-12.p-5p {
    min-height: 40px;
}

.dragDropWrap .dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 250px;
    min-height: 150px;
}

.dragDropWrap .dropzone .imgWrap {
    height: 50px;
    width: 50px;
    margin-bottom: 10px;
}

.dragDropWrap .dropzone .imgWrap img {
    width: 100%;
}

.dragDropWrap .dropzone p {
    text-align: center;
    margin-bottom: 0;
    padding: 0 7px;
    font-size: 13px;
    line-height: 1.2;
}

.detailDrawingDragDrop.dragDropWrap {
    margin-top: 0px;
}

.detailDrawingDragDrop.dragDropWrap .dropzone .imgWrap {
    width: 50px;
    height: 50px;
}

.detailDrawingDragDrop.dragDropWrap .dropzone {
    min-height: 150px;
}

.fileUploaded .collapse:not(.show) {
    display: block;
}

.detailDrawingBlock .accordWrap.custTableMin {
    flex: 1;
    padding-left: 10px;
}

.detailDrawingBlock .detailDrawingDragDrop.dragDropWrap .dropzone {
    max-width: unset;
}

.detailDrawingBlock .a_dragDropWrap {
    max-width: 180px;
    flex: 0 0 180px;
}

header .dropdown-item {
    padding: 3px 5px;
}

.wizard>.actions a,
.wizard>.actions a:active,
.wizard>.actions a:hover {
    cursor: pointer;
}

/* Custom Pagination */
.custom-pagination-dropdown.dropdown .dropdown-menu.show {
    top: auto !important;
    bottom: 100% !important;
    margin-bottom: 10px;
}
/* Custom Pagination */

@media screen and (max-width:767px) {
    .detailDrawingBlock .d-flex.outerWrap {
        flex-wrap: wrap;
    }

    .detailDrawingBlock .a_dragDropWrap {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .detailDrawingBlock .accordWrap.custTableMin {
        padding-left: 0px;
    }
}

@media screen and (max-width:575px) {

    .col-md-8.accordWrap.custTableMin tr,
    .col-md-8.accordWrap.custTableMin tr td {
        display: block;
    }

    .text-right {
        text-align: left;
    }
}

/* TAB COMPONENT CSS STARTS HERE */
.tab-list {
    border-bottom: 1px solid #ccc;
    padding-left: 0;
    cursor: pointer;
  }

.tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    font-weight: 800;
    cursor: pointer;
  }
  
  .tab-list-active {
    background-color: #5b73e8;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
    color: #fff;
    font-weight:800;
    cursor: pointer;
  }
/* TAB COMPONENT CSS ENDS HERE   */

/* SHOP MAINTENANCE DESCRIPTION PAGE MULTIPLE SELECT WITH CHECKBOX CSS */
.fixTableHead {
    overflow-y: auto;
    max-height: 228px;
    border: 1px solid #ced4da;
    min-height: 228px;
    display: flex;
    margin-bottom: 0;
}
.fixTableHead > div:first-child {
    width: 60%;
    border-right: 1px solid #ced4da;
    height: 100%;
}
.fixTableHead > div:last-child {
    width: 40%;
}
.fixTableHead.maintenanceField > div{
    width: calc(100% / 4);
    border-right: 1px solid #ced4da;
    height: 100%;
}
.fixTableHead .tableHeading {
    background: #5b73e8;
    color: #fff;
    padding: 8px 15px;
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.tableCell{
    padding: 8px 15px;	
}


.fixTableHead .fixTableHeadCol .tableCell:nth-of-type(odd){
    background-color: #fff;
}
.fixTableHead .fixTableHeadCol .tableCell:nth-of-type(even){
    background-color: #f2f4ff;
}
.form-check-input {
    cursor: pointer;
}

/* DISABLE BUTTON ACTIVATED, WHEN "ADD MAINTENANCE DATA" SAVED AND DATA IN NOT NULL */
span.disabled:hover{
    cursor:no-drop;
}

span.action-btn{
    font-size:larger;    
}

span:hover{
    cursor: pointer;
}

.fixTabHeading{
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: #fff;
}

/* SHOP MAINTENANCE DESCRIPTION PAGE MULTIPLE SELECT WITH CHECKBOX CSS */

.react-datepicker-popper {
    z-index: 2;
}
 /* inventory Page Css */

 .inventory-wrapper{
    background: #fff;
}

.inventory-card {
    max-width: 90%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 3;
    padding: 25px;
    margin-bottom: 30px;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 0px 10px rgba(30, 31, 32, .25);
}

.inventory-card,
.inventory-item:nth-child(8n+5) .inventory-card{
    background: #b6d7a8;
}

.inventory-item:nth-child(8n+2) .inventory-card,
.inventory-item:nth-child(8n+6) .inventory-card{
    background: #ffe599;
}

.inventory-item:nth-child(8n+3) .inventory-card,
.inventory-item:nth-child(8n+7) .inventory-card{
    background: #ea9999;
}

.inventory-item:nth-child(8n+4) .inventory-card,
.inventory-item:nth-child(8n) .inventory-card{
    background: #b4a7d6;
}

.inventory-card .inventory-card-title {
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
}

.inventory-card .inventory-card-title i {
    margin-right: 10px;
    font-size: 20px;
    line-height: 1;
}

.inventory-card .inventory-card-update {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 3;
    padding: 2px 10px;
    border-radius: 20px;
    font-size: .7em;
    text-transform: uppercase;
}

.inventory-update-model .inventory-option{
    border-color: #ced4da !important;
}

.inventory-update-model .inventory-option .text i{
    margin-right: 10px;
    font-size: 19px;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
}
.inventory-update-model .inventory-option button{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    font-size: 20px;
    border: none;
    background: transparent;
    color: #f46a6a;
}

.inventory-update-model .inventory-option-add .form-control{
    margin-right: 20px;
}




@media screen and (max-width: 767px) {

    .inventory-card {
        max-width: 100%;
    }

    .inventory-card .inventory-card-update {
        right: 6px;
        top: 6px;
    }
}

    