@media only screen and (max-width: 1580px) {
    .card-body.project-index .form-select.form-control{
        max-width: 230px;
    }
    .card-body.project-index .form-control.date-form-control{
        max-width: 120px;
    }
}
@media only screen and (max-width: 1400px) {
    
    .tab-content-section .table-frame table th, 
    .tab-content-section .table-frame table td {
        font-size: 12px;
        padding: 13px 7px;
        vertical-align: middle;
    }
}
@media only screen and (max-width: 1350px) {
    .card-body.project-index > .d-flex{
        flex-wrap: wrap;
    }
}
@media only screen and (max-width: 1280px) {
    .user-account-page .form-col .content-frame {
        width: 80%;
    }
    .user-account-page .form-col .content-frame .title {
        font-size: 28px;
    }
    .add-user-section .add-user-form {
        max-width: 840px;
    }
}
@media screen and (min-width: 1400px) {
    .main-dashboard-row .flex-button-row .btn {
        height: 120px;
    }
}
@media screen and (max-width: 767px) {
    body .accordWrap .card{
        margin: 0 0 15px;
    }
    .dragDropWrap{
        margin-top: 15px;
    }
}