/*****Layout*****/
body {
    font-family: 'Roboto', sans-serif;
}

/* shop personal padding */

.pb-0{
    padding-bottom: 0px;
}

.form-group {
    /* margin-bottom: 1rem; */
}

.form-control {
    height: auto;
    font-size: .9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}

.btn:focus,
.btn:active {
    outline: none !important;
    box-shadow: none !important;
}

.main-content {
    margin-left: 250px;
    overflow: hidden;
}

.shopPersonal .card {
    box-shadow: 0px 0 6px rgb(15 34 58 / 12%);
}

.page-content {
    padding: calc(70px + 1.25rem) calc(1.25rem / 2) 60px calc(1.25rem / 2);
}

#page-topbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 250px;
    z-index: 1002;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
    box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
    height: 70px;
}

.vertical-menu {
    width: 250px;
    z-index: 1005;
    background: #fff;
    bottom: 49px;
    margin-top: 0;
    position: fixed;
    top: 0;
    -webkit-box-shadow: 0 0 4px rgb(15 34 58 / 12%);
    box-shadow: 0 0 4px rgb(15 34 58 / 12%);
}



/*****Components*****/
.release-date-label {
    margin: 15px 0px 5px 0px;
}

.page-title-box h4 {
    font-weight: 600;
    font-size: 18px !important;
}

.btn-create {
    margin-left: 10px;
}

/******Icons*****/
.project-filter-dropdown i {
    font-size: 20px;
    color: #555b6d;
    margin-left: 15px;
    cursor: pointer;
}

.project-filter-dropdown .form-group {
    margin-bottom: 0;
}

.project-date {
    font-size: 14px;
    font-weight: 600;
    margin-left: 20px;
}

.pipe {
    font-size: 14px;
    margin: 0px 20px 0px 20px;
}

.project-date-label {
    padding-right: 10px;
}

.date-form-control {
    width: 170px;
    ;
}

.project-date-label.to {
    margin-left: 20px;
    ;
}

.project-doc-row {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
}

.project-scope-block {
    position: relative;
}

.project-navigation-block {
    position: absolute;
    width: 300px;
    height: 100%;
    border-bottom: 1px solid #ced4da;
    border-left: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    border-top: 1px solid #ced4da;
    background-color: #fff;
    z-index: 0;
    overflow: auto;
}

.project-details-container {
    margin-left: 320px;
    min-height: 500px;
    padding: 20px 0px 0px 0px;
}

.h4-class {
    font-weight: 500;
    font-size: 18px !important;
}

#noanim-tab-example-tabpane-scopeItems .h4-class {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-group {
    border-radius: 0px;
}

.drawing-row-list {
    padding: 0px 0px 10px 0px;
}

.card-box-top {
    height: 213px;
    overflow-y: auto;
}

.card-box-bottom {
    height: 350px;
    overflow-y: auto;
    margin-bottom: 0px;
}

.project-details-container .card {
    border: 1px solid #ccc;
}

.material-card {
    padding: 0px;
}

.shop-col-left {
    width: 250px;
}

.shop-col-right {
    width: 150px;
}

.shop-comment-box {
    padding: 20px 0px 20px 0px;
}

.desc-top {
    margin-top: 15px;
}

.btn-plus {
    margin-left: 20px;
}

.h4-green {
    color: #34c38f;
}

.project-navigation-block .list-group-item {
    border-bottom: 1px solid #ced4da;
}

.list-group-button {
    padding: 15px 10px 10px 10px;
    border-top: 1px solid #ced4da;
}

.add-scope-btn {
    width: 150px;
}

.edit-scope-btn {
    width: 117px;
}

.steps-container {
    width: 100%;
    height: 100px;
}

.detail-drawing-block {
    padding-top: 50px;
}

.wizard>.actions>ul {
    text-align: center;
}

.material-block .po-block{
    margin-top: 30px;
}

.material-heading .po-heading{
    border: 1px solid #ced4da;
    font-size: 1rem;
}

.po-content,
.material-content,
.freight-content {
    border-top: 1px solid #bbbcbe;
    min-height: 500px;
    position: relative;
}

.btn-add-item {
    width: 150px;
    margin-left: 20px;
}

.material-col-one,.material-col-two,.material-col-three,.po-col-one,.po-col-two,.po-col-three{
    /* padding: 10px 30px 10px 30px; */
    padding: 10px;
}

.material-col-two, .po-col-two {
    border-left: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
}

.po-content-block,
.material-content-block,
.shop-fabrication-block,
.freight-content-block,
.freight-heading {
    padding: 20px;
}

.table-fixed {
    width: 100%;
    background-color: #f3f3f3;
    border: 1px solid #ccc;
}

.table-fixed tbody {
    height: 130px;
    overflow-y: auto;
    width: 100%;
}

/* .table-fixed thead, .table-fixed tbody, .table-fixed tr, .table-fixed td, .table-fixed th {
    display: block;
} */
.table-fixed tbody td {
    /* float: left; */
    cursor: pointer;
}

.table-fixed thead tr th {
    /* float: left; */
    background-color: #ccc;
    border-color: #ccc;
}

.freight-block {
    margin-top: 20px;
}

.freight-title {
    /* padding: 10px 20px 10px 20px; */
    font-size: 1rem;
    font-weight: bold;
}

.freight-heading {
    border-top: 1px solid #bbbcbe;
}

.material-opacity {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #fff;
    opacity: 0.5;
}

.material-add-class {
    background-color: #fde1e1;
    border-color: #fcd2d2;
}

.material-error-msg {
    padding-left: 30px;
    padding-bottom: 5px;
    color: #924040;
}

.material-add-class select {
    color: #ccc;
}

.close-list {
    color: #f46a6a;
    font-size: 1.3rem;
}

.close-btn-right {
    text-align: right;
}

.table-wrapper {
    border: 1px solid #ccc;
}

.table-wrapper .table-fixed {
    border: 0px;
}

.card {
    background-color: transparent;
}

/* Progress Tracker v2 */
ol.progress-list[data-steps="2"] li {
    width: 49%;
}

ol.progress-list[data-steps="3"] li {
    width: 33%;
}

ol.progress-list[data-steps="4"] li {
    width: 24%;
}

ol.progress-list[data-steps="5"] li {
    width: 19%;
}

ol.progress-list[data-steps="6"] li {
    width: 16%;
}

ol.progress-list[data-steps="7"] li {
    width: 14%;
}

ol.progress-list[data-steps="8"] li {
    width: 12%;
}

ol.progress-list[data-steps="9"] li {
    width: 11%;
}

.progress-list {
    width: 100%;
    list-style: none;
    list-style-image: none;
    margin: 20px 0 20px 0;
    padding: 0;
}

.progress-list li {
    float: left;
    text-align: center;
    position: relative;
}

.progress-list .name {
    display: block;
    text-align: center;
    margin-bottom: 0px;
    color: black;
    opacity: 0.5;
    font-size: 12px;
}

.progress-list .step {
    color: black;
    border: 3px solid silver;
    background-color: #fff;
    border-radius: 50%;
    line-height: 1.2;
    width: 2em;
    height: 2em;
    display: inline-block;
    z-index: 0;
}

.progress-list .active .name,
.progress-list .active .step span {
    opacity: 1;
}

.progress-list .step:before {
    content: "";
    display: block;
    background-color: silver;
    height: 0.2em;
    width: 50%;
    position: absolute;
    bottom: 1em;
    left: 0;
    z-index: -1;
}

.progress-list .step:after {
    content: "";
    display: block;
    background-color: silver;
    height: 0.2em;
    width: 50%;
    position: absolute;
    bottom: 1em;
    right: 0;
    z-index: -1;
}

/* .progress-list li:first-child .step:after {
   height: 0.1em; 
} */
.progress-list .done .step:before {
    background-color: #34c38f !important;
}

.progress-list .done .step:after {
    background-color: #34c38f !important;
}

.progress-list .active.middle .step:before {
    background-color: #34c38f !important;
}

.progress-list li:first-of-type .step:before {
    display: none;
}

.progress-list li:last-of-type .step:after {
    display: none;
}

.progress-list .done .step,
.progress-list .done .step:before,
.progress-list .done .step:after,
.progress-list .active .step,
.progress-list .active .step:before {
    background-color: #fff;
}

.progress-list .done .step,
.progress-list .active .step {
    border: 3px solid #34c38f;
}

.progress-list .active .name {
    color: #34c38f !important;
    margin-bottom: 0px;
    font-weight: bold;
}

.progress-list .done .name {
    color: #34c38f !important;
    opacity: 1;
}

.progress-list .done .step .uil-check {
    color: #34c38f !important;
    font-size: 1.3rem;
    font-weight: bold;
}

.project-detail-row .card-body {
    cursor: pointer;
    background: #fff;
    position: relative;
}

.material-card-box {
    display: none;
}

.modal-project-detail {
    max-width: 80%;
    text-align: left;
    margin: auto;
    position: relative;
    top: 5px;
}

.app-search {
    width: 400px;
}


.list-dropdown {
    position: absolute;
}

.list-group-drop-down {
    position: absolute;
    left: 298px;
    width: 300px;
    background-color: #fff;
    z-index: 10;
    border: 1px solid #ccc;
    display: none;
}

.list-group-item:hover ul {
    display: block;
}

.dropdown-menu {
    width: 100%;
}

.btn-add-plus {
    margin-left: 220px;
}

.dropdown-toggle .uil-angle-right-b {
    position: absolute;
    right: 5px;
    font-size: 20px;
    top: 7px;
}

.project-detail-page .card {
    background-color: #fff;
}

.mr-top-0 {
    margin-top: 0px !important;
}

.scope-items-ipad {
    display: none !important;
    padding-bottom: 0px;
}

.scope-item-buttons {
    padding: 5px 20px 0px 0px;
}

.project-index {
    background: #fff;
}

.project-index .project-button-box {
    margin-left: 20px;
}

.calendar-drop-down-btn {
    width: 100%;
    text-align: left;
}

.calendar-drop-down-btn .uil-angle-down {
    position: absolute;
    right: 17px;
    font-size: 20px;
    top: 4px;
}

#calendar button {
    color: #fff;
    background-color: #5b73e8;
    border-color: #5b73e8;
}

#calendar button.fc-button-active {
    color: #fff;
    background-color: #495cba;
    border-color: #4456ae;
}

#calendar .fc-toolbar button {
    text-transform: capitalize;
    box-shadow: none;
}

.fc-h-event .fc-event-title {
    display: block;
    text-align: left;
    font-weight: 600;
}

.fc-h-event .fc-event-title .description {
    font-weight: 400;
}

.checkbox-overlay {
    position: absolute;
    width: 500px;
    max-height: 400px;
    min-height: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    z-index: 10;
    overflow: auto;
}

.checkbox-overlay-padding {
    padding: 10px;
}

.checkbox-overlay-links a {
    text-decoration: underline !important;
}

.checkbox-overlay-links .uncheck-all {
    margin-left: 15px;
}

.checkbox-close .uil-multiply {
    font-size: 15px;
    font-weight: bold;
}

#calendar {
    margin-top: 10px;
}

.fc-daygrid-event {
    color: #fff;
    text-align: left;
    white-space: normal;
}

.fc-daygrid-event:hover {
    color: #fff;
}

.modal-project-detail .main-content {
    background: #fff;
    margin-left: 0;
    height: calc(100vh - 70px);
    overflow: auto;
}

.modal-project-detail .page-content {
    padding-top: 20px;
    padding-bottom: 0px;
}

.closeWrap {
    background: #fff;
    text-align: right;
    padding: 10px;
}

.modal-conatiner{
    width: 50%;
}
.modal-project-detail .closeBtn {
    /* position: absolute; */
    /* right: 15px; */
    /* top:15px; */
    /* padding: 2px 8px; */
}
.scopeItemPopUpComp {
    width: 85% !important; 
    max-width: 500px !important;
}
.projListing table thead tr th, .projListing table tbody tr td{
    width: 50%;
}

.scopelisttable table tbody tr td:first-child,.scopelisttable table thead tr td:first-child{
    width: 60%;
}
.scopelisttable table tbody tr td:last-child,.scopelisttable table thead tr td:last-child{
    width: 40%;
}
.scopelisttable table {
    table-layout: auto !important;
}
.scopelisttable table tbody tr td:first-child, .scopelisttable table thead tr td:first-child {
    font-size: 20px;
}
footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    background: #f5f6f8;
    z-index: 3;
}

.saveBtn .btn {
    padding: 0.4rem 2rem;
    font-size: 16px;
}

.project-navigation-block .form-check-input {
    width: 20px;
    height: 20px;
    margin: 0;
}

.project-navigation-block .form-check-label {
    margin-left: 10px;
    font-weight: 400;
}

/*****Media Query****/

@media screen and (max-width: 1440px) {
    .project-details-container .card {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 1366px) {
    .project-details-data .col-md-6 {
        width: 50%;
    }

    .project-details-data .col-md-12 {
        width: 100%;
        margin-top: 20px;
    }

    .h4-hide {
        display: none;
    }

}

@media only screen and (max-width: 1220px) {
    .project-navigation-block {
        width: 100%;
        position: static;
    }

    .project-details-container {
        margin-left: 0;
        min-height: 500px;
        padding: 20px 0px 0px 0px;
    }

    .detail-drawing-block {
        padding-top: 20px;
    }
}

@media only screen and (max-width: 1024px) {
    /* .project-navigation-block {
        display: none;
    } */

    .project-details-container {
        margin-left: 0px;
    }

    .h4-hide {
        display: none;
    }

    .project-details-data .col-md-6 {
        width: 50%;
    }

    .project-details-data .col-md-12 {
        width: 100%;
        margin-top: 20px;
    }

    .h4-hide {
        display: none;
    }

    .table-fixed .col-xs-6 {
        width: 50%;
    }

    .table-fixed .col-xs-3 {
        width: 25%;
    }

    .scope-items-ipad {
        display: flex !important;
    }

    .project-navigation-block .list-group-buttons {
        display: none !important;
    }

    .project-top-section .col-md-4 {
        width: 33.33333%;
    }

    .project-top-section .col-md-3 {
        width: 25%;
    }

    .project-top-section .col-md-2 {
        width: 16.66667%;
    }

    .pipe {
        display: none;
    }

    .project-index .col-md-12 {
        width: 100%;
    }

    .project-index-filter {
        margin-top: 10px;
    }
    .fileOuter{
        flex-wrap: wrap;
    }
    .fileOuter p{
        flex:0 0 100%;
    }
}

@media screen and (max-width: 992px) {
    #page-topbar {
        left: 0;
    }

    .main-content,
    footer {
        margin-left: 0px;
    }
    .vertical-menu {
        top: 70px;
    }
    .vertical-menu.collapse.show{
        display: block;
    }
    .project-details-container .card .card-body{
        padding: 10px;
    }
    .drawing-row-list button{
        padding: 5px 8px;
        font-size: 0.8rem;
    }
    .drawing-title{
        flex:0 0 150px;
        max-width: 150px;
    }
}

@media only screen and (max-width: 768px) {
    .table-fixed .col-xs-6 {
        width: 50%;
    }

    /* .project-navigation-block {
        display: none;
    } */

    .project-details-container {
        margin-left: 0px;
    }

    .h4-hide {
        display: none;
    }

    #page-topbar {
        left: 0px;
    }

    .vertical-menu {
        top: 70px !important;
    }

    .navbar-brand-box {
        padding: 0px;
    }

    .progress-list .name,
    .progress-list .active .name {
        font-size: 11px;
    }

    .progress-list .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}


/**************Common****************/
.card-block {
    background-color: #fff;
}


/* add user css */
.addUser-form .wizard>.actions>ul {
    text-align: right;
}

.uploadFile-image .imagePreview {
    width: 180px;
    height: 180px;
    border-radius: 100%;
    background-position: center center;
    background: url(http://cliquecities.com/assets/no-image-e3699ae23f866f6cbdf8ba2443ee5c4e.jpg);
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    display: inline-block;
    box-shadow: 0px -3px 6px 2px rgba(0, 0, 0, 0.2);
    margin: 10px auto;
    display: block;
}

.uploadFile-image .btn-primary {
    display: inline-block;
    box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.2);
    margin-top: -5px;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 6px;
    right: 60px;
    line-height: 12px;
    padding: 6px 3px;
}

.uploadFile-image .imgUp {
    margin-bottom: 15px;
}

.react-datepicker-wrapper input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #495057;
    opacity: 1;
    text-transform: lowercase;
}

.react-datepicker-wrapper input::-moz-placeholder {
    /* Firefox 19+ */
    color: #495057;
    opacity: 1;
    text-transform: lowercase;
}

.react-datepicker-wrapper input:-ms-input-placeholder {
    /* IE 10+ */
    color: #495057;
    opacity: 1;
    text-transform: lowercase;
}

.react-datepicker-wrapper input:-moz-placeholder {
    /* Firefox 18- */
    color: #495057;
    opacity: 1;
    text-transform: lowercase;
}

.editProjectDatePickerWrap .date-form-control {
    width: 100%;
}

.a_BtnPlus {
    font-weight: bold;
    color: #fff;
    background-color: #4d62c5;
    border: 1px solid #495cba;
    font-size: .9rem;
    border-radius: .25rem;
}

.headerMinHeight50 th,
.headerMinHeight50 thead {
    min-height: 51px;
    background: #ccc;
}

.autoSuggestWrap .react-autosuggest__input {
    display: block;
    width: 100%;
    padding: .47rem .75rem;
    font-size: .9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.autoSuggestWrap .react-autosuggest__container {
    flex: 1;
    position: relative;
}

.autoSuggestWrap .react-autosuggest__input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #495057;
    opacity: 1;
}

.autoSuggestWrap .react-autosuggest__input::-moz-placeholder {
    /* Firefox 19+ */
    color: #495057;
    opacity: 1;
}

.autoSuggestWrap .react-autosuggest__input:-ms-input-placeholder {
    /* IE 10+ */
    color: #495057;
    opacity: 1;
}

.autoSuggestWrap .react-autosuggest__input:-moz-placeholder {
    /* Firefox 18- */
    color: #495057;
    opacity: 1;
}

.autoSuggestWrap .react-autosuggest__suggestions-container {
    background: #fff;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, .3);
}

.autoSuggestWrap .react-autosuggest__suggestions-container ul {
    padding: 0;
    margin: 0;
}

.autoSuggestWrap .react-autosuggest__suggestions-container ul li {
    padding: 10px;
}

.ReactModal__Content {
    padding: 0 !important;
    overflow-y: auto;
    max-height: 100%;
}

.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.5) !important;
    z-index: 1050;
}

.date-form-control {
    width: 100%;
}

.addMaterialPopupWrap .modal-body {
    height: 280px;
}

.react-autosuggest__suggestions-list {
    max-height: 200px !important;
    overflow-y: auto !important;
}

.metismenu {
    padding-left: 20px;
}

.metismenu .mm-collapse,
.metismenu .mm-collapsing,
.metismenu .mm-collapse.mm-show {
    padding-left: 10px;
}

.simplebar-wrapper {
    overflow: auto !important;
}

.react-bootstrap-table-pagination .dropdown .dropdown-menu.show {
    top: auto !important;
    bottom: 100% !important;
    margin-bottom: 10px;
}

.min-60 {
    min-width: 60px;
}

.shop-row-list {
    padding: 4px 0;
}

.shop-row-list {
    margin: 5px 0;
}

.shop-row-list.shop1-row{
    justify-content: space-between;
} 

.shop-row-list.shop1-row .shop-col-left{
    flex: 1;
    width: unset;
}
.shop-row-list.shop1-row .shop-col-right{
    flex: 0 0 200px;
    width: 200px;
    text-align: right;

}

.shop-row-list.shop1-row .shop-col-right .view-files-btn{
   width: 120px;
   margin-left: 5px;
}

.card-title {
    font-weight: 500;
    font-size: 15px;
}

.userDetialsMin {
    display: flex;
    align-items: center;
}

.userDetialsMin a.text-body {
    display: inline-block;
    white-space: nowrap;
    max-width: 163px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.outerWrap {
    margin-bottom: 30px;
    border: 1px solid #cecece;
    padding: 8px 5px 0;
    border-radius: 5px;
    background: #fff;
}

.accordWrap .card {
    box-shadow: none;
    border: 1px solid #ced4da;
    margin: 29px 0 10px;
}

.accordWrap .card-body {
    padding: 0;
}

.accordWrap .card-header {
    background: #f5f6f8;
    border-radius: .25rem;
    padding: 8px 12px;
    cursor: pointer;
}

.accordWrap .card-header:hover {
    background: #ced4da;
}

.accordWrap tr td {
    background: #fff;
    padding: 8px 12px;
}

.accordWrap tr td .btn {
    padding: 3px 12px;
    font-size: 13px;
}

.accordWrap tr:nth-child(2n+1) td {
    background: rgb(232, 237, 247);
}

.accordWrap .project-doc-row {
    border: none;
    padding: 11px;
    background: rgb(232, 237, 247);
}

.accordWrap .project-doc-row:nth-child(2n) {
    background: #fff
}

.uploadImgWrap {
    max-width: 180px;
    position: relative;
}

.uploadFile-image .uploadImgWrap .btn-primary {
    bottom: 0;
    right: 10px;
}

.addMaterialPopupWrap .close-list {
    line-height: 0px;
    font-size: 22px;
}

.addMaterialPopupWrap .modal-body {
    height: auto;
}

.addMaterialPopupWrap .table-fixed tbody {
    height: auto;
}

.project-details-container .card {
    background: #fff;
}

.mr-3 {
    margin-right: 1.25rem;
}

.unsavedItem {
    font-weight: 500;
    color: #6279e9;
    font-style: italic;
}

/* .card-body{background: #fff;} */
.bgW {
    background: #fff;
}

.bgG {
    background: #ffffff82;
}

.steps-container {
    position: relative;
    z-index: 1;
}

.titleCol {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 220px;
    display: inline-block;
}

.time-Off-modal-wrap .modal-body,
.time-Off-modal-wrap .modal-header,
.time-Off-modal-wrap .modal-footer {
    padding-left: 25px;
    padding-right: 25px
}
.table-responsive .react-bootstrap-table table{
    table-layout: initial;
}
.whiteSpacing table th{
    white-space: nowrap;;
}
.mt-n1{
    margin-top: -0.25rem !important;;
}
.alert-sidebar-menu-scroll {
    position: fixed;
    height: calc(100% - 70px);
    top: 70px;
    right: -545px;
    background-color: #fff;
    width: 545px;
    z-index: 1002;
    max-width: 100%;
    box-shadow: 0 0 4px rgb(15 34 58 / 12%);
    border: 1px solid #ced4da;
    transition: all 1s;
}
.alert-header,.alert-list li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 17px;
    border-bottom: 1px solid #ced4da;
    color: #495057;
}
.alert-header h5 {
    font-family: Roboto, sans-seri;
    font-weight: 800;
    font-size: 16px;
}
.alert-list{
    padding: 0;
}
.notification-number {
    position: absolute;
    width: 24px;
    height: 24px;
    font-size: 15px;
    line-height: 24px;
    color: #000;
    border-radius: 50%;
    font-weight: bold;
    right: -10px;
    top: -10px;
    border: 1px solid #ff6782;
    background-color: #ffffd0;
}

.notification-number.alert-three-digit {
    width: 30px;
    height: 30px;
    line-height: 30px;
    right: -15px;
    top: -15px;
}

.notification-number.alert-four-digit {
    width: 40px;
    height: 40px;
    line-height: 40px;
    right: -20px;
    top: -20px;
}

.upItem .col-md-4,
.accordWrap .col-md-4{
    margin-bottom: 10px;
}
.upItem .col-md-4 p{
    margin-bottom: 0;
}
.upItem label{
    white-space: nowrap;
    width: 99%;
    text-overflow: ellipsis;
    overflow: hidden; 
}
.project-scope-block label,
.accordWrap label{
    white-space: nowrap;
    width: 99%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.form-project button{
    margin: 0;
}
.material-content-block .po-content-block .table-fixed thead tr th{
    float: none;
}
.material-content-block .po-content-block .table-fixed thead tr th.col-xs-2 .a_BtnPlus{
    margin: 0;
}
.inventorySearch{
    margin-right: 4px;
    display: flex;
    align-items: baseline;
}
.inventoryLocationDropDownList{
    margin-right: 14px;
}
.material-block .material-content .po-block .po-content{
    min-height: auto;
}

.block-height .material-content,
.block-height .po-content{
    min-height: inherit;
}
.drawing-row-list button{
    white-space: nowrap;
    flex-shrink: 0;
}
.drawing-title{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex:1;
}
.drawing-Wrap {
    display: flex;
    justify-content: space-between;
    flex:1;
}
.drawing-title-1{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex: 0 0 130px;
} 
.drawing-Wrap-1 {
    display: flex;
    justify-content: space-between;
    flex: 0 0 160px;
    max-width: 160px;
}
.clip-Icon{
    flex:0 0 20px;
    max-width: 20px;
}
.receive-card.card{
    background: #ffffff;
}

/*******UI Fixes Project Card*****/
.uil-trash-alt-proj {
    position: absolute;
    right: 15px;
    top: 78px;
}
@media screen and (max-width: 800px) {
    .project-scope-block .react-bootstrap-table table {
        font-size: 0.85rem;
    }
    .project-scope-block .react-bootstrap-table table tr th:nth-child(6)  {
        width: 130px;
    }
    .project-scope-block .react-bootstrap-table table tr th, .project-scope-block .react-bootstrap-table table tr td {
        padding: 0.5rem;
    }
}
.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    top: 100%;
    left: 0;
    right: 0;
}
.autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    text-align: left;
}
.autocomplete-block {
    position: relative;
}
.invent-select {
    width: 200px
}
.project-select {
    width: 200px;
}
.search-project-btn {
    margin-left: 0.8rem;
}
@media screen and (max-width: 900px) {
    .invent-select {
        width: 150px;
    }
    .manual-inventory-block .dl-btn, .manual-inventory-block .add-inventory {
        font-size: 0.9rem;
    } 
    .manual-inventory-block {
        justify-content: space-between !important;
    }
    .search-inventory-text {
        width: 160px
    }
}
/* addding scrollbar in a material freight and receiving table using bootstrap */
.my-custom-scrollbar {
    position: relative;
    height: 200px;
    overflow: auto;
    }
    .my-custom-scrollbar-print {
        position: relative;
        /* height: 200px; */
        overflow: visible;
        }
    .table-wrapper-scroll-y {
    display: block;
    }

    /* styling to make the  the lable no slide selected hidden */
    .vendor_logo_hide {
        display: inline !important;
        ;
        color: transparent;
        width: 99px;
      }
      
      .vendor_logo {
        display: block !important;
        color: black;
        width: 100%;
      }
      /*Css to keep the heading sticky while table is scrolled in freight material and receiving section */
      .headerTable{
        position:sticky;
        z-index: 9;
        top: 0 ;
    }

.table th{
   padding: 1.2rem .70rem
}
.apexcharts-yaxis-label,
.apexcharts-xaxis-label{
    fill: #000 !important;
}
.headingTxt {
    font-size: 18px;
}
.fileOuter{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.fileOuter p {
    flex: 0 0 50%;
    margin-bottom: 0px;
    font-size: 15px;
    border: 1px solid #5b73e8;
    padding: 4px;
    border-radius: 4px;
}
.fileOuter .btnWrapper{
    flex:1;
}
.accordWrap .fileOuter label.btn{
    width: auto;
}
.categoryHeading{
    margin-bottom: 20px;
}

.categoryData {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0px;
}
.card-body table{
    font-size: 16px;
}
    
.categoryOuter{
    padding: 20px 10px;
    box-shadow: 0px 0 6px rgb(15 34 58 / 12%);
    border-radius: 4px;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
  
  .dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
  }

  .ReactModalPortal .freight-title {
    margin-top: -10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ReactModalPortal .freight-title.transport-title {
    margin-top: 10px;
  }

  .btn-shop-download {
    margin-right: 20px;
  .listData{
    padding: 0;
    border: 1px solid #ced4da;
    border-top: 0;
    margin-top: -2px;
    cursor: pointer;
  }

  .listData li{
    padding: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ced4da;
  }
  .listData li:last-child{
    border-bottom: 0;
    margin-bottom: 0;
  }

  .editorForm{
    padding: 20px 20px;
    /* border: 1px solid rgb(204, 200, 200); */
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    /* align-items: center; */
    /* margin-top: 50px; */
    width: 60%;
  }
}

  .employeeListPopup{
    /* border: 2px solid red;  */
    /* width: 95%; */
    padding: 10px 10px;
  }
  .employeeListPopup tbody td, .employeeListPopup thead th {
    font-size: 16px;
  }

.add-material-block .modal-header .btn-close {
    margin-right: 5px;
}
.table-scroll {
    padding-bottom: 13px;
}
.massdeleteInventory
{
    border: 3px solid #ced4da;
    text-align: center;
}
.massdeleteInventory th{
    font-size: 20px;
}
.w-200 {
    width: 250px;
}
.flex-container {
    flex-wrap: wrap;
}
.flex-container > div {
    margin-right: 15px;
}

/* Add this CSS to your stylesheet */
.select-sm {
    width: auto;
    max-width: 150px; /* Adjust the width as needed */
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}
.w-150{
    width: 150px !important;
}   
.btn-done{
    padding: 10px 45px;
}

/* Custom CSS for tabs at 768px screen width */
@media (max-width: 768px) {
    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;
    }
  
    .nav-tabs .nav-item {
      flex: 1;
    }
  
    .nav-tabs .nav-link {
      white-space: nowrap;
      text-align: center;
    }
  }

  @media (max-width: 1024px) {
    .nav-tabs {
      display: flex;
      flex-wrap: nowrap;
    }
  
    .nav-tabs .nav-item {
      flex: 1;
    }
  
    .nav-tabs .nav-link {
      white-space: nowrap;
      text-align: center;
    }
  }


  .table-wrapper-scroll-y {
    display: block;
    max-height: 400px; /* Adjust this value based on your layout */
    overflow-y: auto;
  }
  
  .table-fixed thead {
    position: sticky;
    top: 0;
    z-index: 1; /* Ensure it stays above other content */
    background-color: white; /* Ensure the background color is set */
  }
  /* scope items css classes */

 
  .list-group-item-orange {
    background-color: rgb(243, 123, 87);
  }
  
  .list-group-item-warning {
    background-color: rgb(248, 224, 86);
  }
  
  .list-group-item-secondary {
    background-color: rgba(112, 104, 104, 0.87);
  }
  
  .list-group-item-success {
    background-color: rgb(86, 202, 86);
  }
  
  .list-group-item-danger {
    background-color: rgb(197, 14, 8);
  }
  .list-group-item-primary{
    background-color: rgb(11, 85, 231);
  }
  .selected-item {
    border: 5px solid rgb(255, 255, 255); 
    /* // Add an outline or border to highlight the selected item */
  }

  /* preventing hovering color of the scope item list  */
  
  /* Prevent default hover effect */
.list-group-item-action:hover {
    color: inherit;
    background-color: inherit;
    border-color: inherit;
}

/* Override Bootstrap's hover background color */
.list-group-item-action.dropdown-toggle:hover {
    background-color: transparent !important;
}


  .list-textColor{
    color:#000000
  }

/****UI Fixes***/
.transport-list-content {
    padding: 20px 15px 20px 15px;
}
.transport-list-content .btn-close{
    position: absolute;
    right: 15px;
    top: 13px;
}
.transport-list-content h5 {
    padding-bottom: 5px;
}
.table-delivery-popup thead th {
    white-space: nowrap;
}

.table-transport-popup thead th {
    white-space: nowrap;
}
.delete-button-box{
    display: flex;
    gap: 20px;
}
.delete-button-box .uil-pen{
    cursor: pointer;
}
.table-wrapper-scroll-transport {
    display: block;
    max-height: 250px;
    min-height: 205px;
    overflow-y: auto;
  }
  .measureWrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .measureWrap label{
    flex:1;
    color: #5b73e8;
  }


  /* camera comp css */


  /* General styling for form elements */
.form-label {
    margin-bottom: 10px;
    display: inline-block;
  }
  
  /* Camera section */
  .camera-section {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }
  
  /* Flexbox for camera input: camera icon and view files button aligned horizontally */
  .camera-input {
    display: flex;
    align-items: center; /* Align icon and button vertically centered */
    margin-top: 5px;
  }
  
  /* Style for the camera icon */
  .camera-input .fa-camera {
    font-size: 24px;
    margin-right: 10px; /* Space between camera icon and button */
    cursor: pointer;
  }
  
  /* Style for the View Files button */
  .view-files-btn {
    padding: 5px 10px;
    background-color: #007bff; /* Blue button */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .view-files-btn:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  /* Responsive behavior */
  @media (max-width: 768px) {
    .camera-section {
      width: 100%; /* Full width for smaller screens */
    }
  
    .camera-input {
      width: 100%; /* Ensure the icon and button take full width on smaller screens */
      justify-content: space-between; /* Spread items evenly in small screens */
    }
  
    .view-files-btn {
      width: auto; /* Ensure the button doesn't take full width */
    }
  }
  /* Ensuring button is wider and text stays in one line */
.view-files-btn {
    width: 150px; /* Increase the width of the button as needed */
    white-space: nowrap; /* Prevent text from breaking into multiple lines */
    text-align: center; /* Center the text */
    padding: 8px 12px; /* Adjust padding for a better look */
    background-color: #007bff; /* Standard primary button color */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .view-files-btn:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  /* Responsive behavior to adjust width on smaller screens if needed */
  @media (max-width: 768px) {
    .view-files-btn {
      width: 100%; /* Optional: make button full width on smaller screens */
    }
  }
  
  .massDel{
    font-size: 16px;
  }

/* rental equipment download pop up css */

/* Ensures consistent spacing between dropdowns */
.gap-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; /* Adjust the gap between items */
  }
  
  .dropdown-container {
    flex: 1 1 calc(33.33% - 1rem); /* Dynamic width with gap consideration */
    min-width: 200px; /* Ensure dropdowns don't shrink too much */
  }
  
  .dropdown-label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #333;
  }
  
  .form-select {
    width: 100%;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 1rem;
    padding-right: 33px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .dropdown-container {
      flex: 1 1 100%; /* Dropdowns stack vertically on smaller screens */
    }
  }
  
  /* css for rental equipment */
  .large-checkbox {
    width: 20px; 
    height: 20px; 
    transform: scale(1.5); 
    margin: 5px;
  }
  /* spacing required in the left bar */
  .span-spacing {
    margin-left: 8px; /* Adjust the spacing as needed */
  }
